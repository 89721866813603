/**
 * 
 * @param dateString date to be formatted
 * @returns  format date based on current week or past date
 */
export function getFormattedDateTime(dateString: string) {
    const date = new Date(dateString);

    // Current date and time
    const now = new Date();

    // Check if date is within the current week
    const isCurrentWeek = date >= new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());

    // Options for formatting the date
    const options = {
        hour: '2-digit', // 2-digit hour (e.g., 08)
        minute: '2-digit', // 2-digit minute (e.g., 10)
        hour12: true, // use 24-hour format
    };

    // Format the date and time
    let formattedDate;
    if (isCurrentWeek) {
        // Display only day and time for the current week
        const dayOptions = {
            weekday: 'long', // full day name (e.g., "Tuesday")
            hourCycle: 'h12',
            ...options
        };
        // @ts-ignore
        formattedDate = new Intl.DateTimeFormat('en-US', dayOptions).format(date);
    } else {
        // Display full date and time for other weeks
        const fullOptions = {
            // weekday: 'long', // full day name (e.g., "Tuesday")
            // year: 'numeric', // 4-digit year (e.g., 2024)
            month: 'long', // full month name (e.g., "June")
            day: 'numeric', // numeric day (e.g., 20)
            ...options
        };
        // @ts-ignore
        formattedDate = new Intl.DateTimeFormat('en-US', fullOptions).format(date);
    }

    return formattedDate;
}

export function formatMillisecondsToDate(ms: string) {

    // Convert Unix timestamp to milliseconds
    const milliseconds = Number(ms) * 1000;

    // Create a Date object from the milliseconds
    const date = new Date(milliseconds);

    // Extract hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format hours and minutes to always be two digits
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Combine into HH:MM format
    const localTimeString = `${formattedHours}:${formattedMinutes}`;

    return localTimeString
}

export function convertUTCToLocalTimezone(utcDateString: string) {
    // Parse the UTC datetime string into a Date object
    // Ex: 1726123167 => 12 September 2024 at 12:23:54 pm
    // @ts-ignore
    const utcDate = new Date(utcDateString * 1000); // Adding ' UTC' to indicate it's UTC time
    console.log('convertUTCToLocalTimezone utcDate: ', utcDate);

    // Get browser's timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Define options for formatting the date
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: timezone,
    };
    
    // Convert to local time and format
    // @ts-ignore
    const localDateString = utcDate.toLocaleString('en-US', options).replace(',', ''); // Remove comma for desired format
    console.log('convertUTCToLocalTimezone localDateString: ', localDateString);

    return localDateString;
}

export function getFilteredConfiguration(configurations: object) {
    // Using Object.entries and reduce to filter properties with true values
    const filteredObj = Object.entries(configurations)
        .filter(([key, value]) => value === true)
        .reduce((acc, [key, value]) => {
            // @ts-ignore
            acc[key] = value;
            return acc;
        }, {});
    console.log('filteredObj: ', filteredObj);

    return filteredObj
}

export const handleKeyPressForMeetingName = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow only alphabetic characters and whitespace
    if (
        (e.key >= 'A' && e.key <= 'Z') || // A-Z
        (e.key >= 'a' && e.key <= 'z') ||  // a-
        (e.key >= '0' && e.key <= '9')  // a-z

    ) {
        // Append the typed character to the current text
        return;
    }
    // Prevent default behavior for all other keys
    e.preventDefault();
};

export const validateEmail = (email: string): boolean => {
    // Regular expression for validating email addresses
    if (!email) {
        return true
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    console.log(' emailRegex.test(email): ', emailRegex.test(email));
    return emailRegex.test(email);
}

export function getRoomFromURL(): string {
    // Get the URL of the current page
    const currentURL = window.location.href;
    let segments;

    // Split the URL into an array of segments using the forward slash as a delimiter
    if (currentURL.includes('/reactclientapp/')) {
        segments = currentURL?.split('/reactclientapp/');
    } else if (currentURL.includes('/reactclientapp/#/')) {
        segments = currentURL?.split('/reactclientapp/#/');
    } else if (currentURL.includes('/reactclientapp#/')) {
        segments = currentURL?.split('/reactclientapp#/');
    } else {
        segments = currentURL?.split('/reactclientapp');
    }

    // Access the last segment of the array to get the last part of the URL
    let getRoomFromURL = segments[segments?.length - 1];
    getRoomFromURL = getRoomFromURL.replace('#/', '');
    console.log('_getGuestRoomName getRoomFromURL: ', getRoomFromURL);
    return getRoomFromURL;
}

