import { FunctionComponent, useState } from "react";
import { Input } from "antd";
import { useSelector } from "react-redux";

//Constants
import { CONFIGURATIONS } from "../../Constants/AppConstants";

//Assets
import CrossIcon from "../../Resources/Images/icadd21@2x.png";
import { ReactComponent as InviteIcon } from "../../Resources/Images/InviteIcon.svg";
import { ReactComponent as SearchIcon } from "../../Resources/Images/union7.svg";
import { ReactComponent as Vector11 } from "../../Resources/Images/vector11.svg";
import ProfileIconSmall from "../../Resources/Images/profileIconSmall.png";
import { ReactComponent as MicOff } from "../../Resources/Images/ic_mic_off.svg";
import { ReactComponent as MicOn } from "../../Resources/Images/mic-on.svg";
import { ReactComponent as VideoOn } from "../../Resources/Images/ic_video_on.svg";
import { ReactComponent as VideoOff } from "../../Resources/Images/IconVideoOffBig.svg";
import { ReactComponent as CopyIcon } from "../../Resources/Images/iconCopy.svg";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Styles
import "./ParticipantsPanel.scss";

export type ParticipantsPanelType = {
	className?: string;
	onClose?: () => void;
	setInvitePopup: Function;
};

const ParticipantsPanel: FunctionComponent<ParticipantsPanelType> = ({
	className = "",
	onClose,
	setInvitePopup,
}) => {
	const participants = useSelector(
		(state: any) => state.ParticipantReducer.participants
	);
	const localParticipant = useSelector(
		(state: any) => state.ParticipantReducer.localParticipant
	);
	const roomname = useSelector((state: any) => state.RoomReducer.roomName);
	const { configurations } = useSelector((state: any) => state.LoginReducer);

	// Invite link
	const link = process.env.REACT_APP_BASE_URL + "/" + roomname;
	const [copied, setCopied] = useState(false);

	console.log("Participants in participants panel: ", participants);

  // Copy meeting link to clipboard
  const handleCopy = (e: any) => {
    //@ts-ignore
    // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
    //   if (result.state === "granted" || result.state === "prompt") {
        /* write to the clipboard now */
        navigator.clipboard
          .writeText(link)
          .then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
          })
          .catch((err) => console.error("Failed to copy: ", err));
      // } else {
      //   console.error('Clipboard write permission denied.');
      // }
    //});   
  };

	return (
		<div className={`participants-panel ${className}`}>
			<div className="chatheader">
				<div className="arrowbackmobile-parent">
					<button className="arrowbackmobile">
						<Vector11 className="vector-icon" />
					</button>
					<div className="participants">{getTranslation("participants")}</div>
				</div>
				<button className="closebutton" onClick={onClose}>
					<img className="icadd2-icon" alt="" src={CrossIcon} />
				</button>
			</div>
			{localParticipant?.isModerator && <div className="buttonprimarywithiconlarge-parent">
				<button
					className="buttonprimarywithiconlarge"
					onClick={() => setInvitePopup(true)}
				>
					<div className="icadd2">
						<InviteIcon className="vector-icon75"
						></InviteIcon>
					</div>
					<b className="sign-in">{getTranslation("emailInvite")}</b>
				</button>
				<button className="buttonsecondarygreen" onClick={(e: any) => handleCopy(e)}>
					<CopyIcon className="iconcopy" title="copy" />
					<b className="join1">{copied ? getTranslation("copiedLink") : getTranslation("copyLink")}</b>
				</button>
				{/* Temporary hide search bar
				<div className="search">
					<Input
						className="search-child"
						name="searchParticipants"
						size="large"
						placeholder="Search Participants"
						allowClear={true}
						type="search"
						bordered={false}
					/>
					<div className="icsearch2">
						<SearchIcon className="union-icon1" />
					</div>
				</div> 
				*/}
			</div>
			}

			<div className="bodytranscript">
				<div className="frame-parent">
					{/* waiting list Hidden for time being
          <div className="frame-group">
            <div className="waiting-parent">
              <div className="waiting">{getTranslation("waiting")}</div>
              <div className="waiting">(2)</div>
            </div>
          </div> */}
					<div className="frame-group">
						<div className="waiting-parent">
							<div className="waiting">{getTranslation("inCall")}</div>
							<div className="waiting">
								{"(" + (participants?.length || 0) + ")"}
							</div>
						</div>
						<div className="waiting-room">
							<div className="members">
								{participants.length > 0 &&
									participants?.map((participant: any) => (
										<div className="participants1" key={participant?.sid}>
											<div className="photo-title">
												<img
													className="photo-title-child"
													alt=""
													src={ProfileIconSmall}
												/>
												<div className="title">
													<b className="peter-anderson">
														{participant?.identity}
													</b>
													<div className="admin">
														{participant?.isModerator ? "Moderator" : ""}
													</div>
												</div>
											</div>
											<div className="acceptdenycontrol">
												{/* <button className="buttonsignin">
                        <img
                          className="icadd2-icon"
                          alt=""
                          src={add}
                        />
                      </button>
                      <button className="buttonsignin1">
                        <img className="vector-icon11" alt="" src={vector5} />
                      </button> */}

												{participant?.audioTracks &&
													participant?.audioTracks[0]?.isEnabled ? (
													<MicOn className="controliconssmall" />
												) : (
													<MicOff className="controliconssmall" />
												)}

												{CONFIGURATIONS.VIDEO_CALL in configurations ?

													participant?.videoTracks &&
														participant?.videoTracks[0]?.isEnabled ? (
														<VideoOn className="controliconssmall" />
													) : (
														<VideoOff className="controliconssmall" />
													)
													: null
												}


											</div>
											{/* not rendering on UI 
                    <div className="audiovideocontrol">
                      <img
                        className="controliconssmall"
                        alt="mic off icon"
                        src={participant?.audioTracks && participant?.audioTracks[0]?.isEnabled ? MicOn : MicOff}
                      />
                      <img
                        className="controliconssmall"
                        alt="video off icon"
                        src={participant?.videoTracks && participant?.videoTracks[0]?.isEnabled ? VideoOn : VideoOff}
                      />
                    </div> */}
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ParticipantsPanel;
