import { Dispatch } from "redux";

//Constants
import {
  ON_MIC_MUTE_UNMUTE,
  ON_VIDEO_MUTE_UNMUTE,
  ON_RECORDING_TOGGLE,
  ON_SCREEN_SHARE_TOGGLE,
  ON_TRANSCRIPT_TOGGLE,
  ON_TRANSCRIPT_ENABLED,
  ON_PARTICIPANT_PANEL_TOGGLE,
  ON_CHANGE_LAYOUT,
  ON_SPEAKER_MUTE_UNMUTE,
  ON_MORE_ACTIONS,
  ON_MORE_ACTIONS_MOBILE,
  ON_END_CALL,
  CLEAR_INCALL_REDUCER,
  LOGOUT,
  CLEAR_ROOM_REDUCER,
} from "../ReduxConstants";

//Service
import proConfService from "../../Services/ProConfService";

//Store
import { store } from "../store";

export async function onMicMuteUnmute(micMute: boolean) {
  if (micMute === false) {
    await proConfService.muteAudio();
  } else {
    await proConfService.unmuteAudio();
  }
  store.dispatch({ type: ON_MIC_MUTE_UNMUTE });
}

export async function onVideoMuteUnmute(videoMute: boolean) {
  if (videoMute === false) {
    await proConfService?.muteVideo();
  } else {
    await proConfService?.unmuteVideo();
  }
  store.dispatch({ type: ON_VIDEO_MUTE_UNMUTE });
}

export function onRecordClick() {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ON_RECORDING_TOGGLE });
  };
}

export function onScreenSharing(isScreenshared: boolean) {
  if (isScreenshared === false) {
    proConfService.startScreenShare();
  } else {
    proConfService.stopScreenShare();
  }
  store.dispatch({ type: ON_SCREEN_SHARE_TOGGLE });
}

export function onTranscriptToggle() {
  store.dispatch({ type: ON_TRANSCRIPT_TOGGLE });
}

export async function onToggleTranscription() {
  const { transcriptionEnabled } = store?.getState()?.ToolbarReducer;
  await proConfService?.enableTranscription(!transcriptionEnabled);
  store.dispatch({ type: ON_TRANSCRIPT_ENABLED });
}

export function onParticipantPanelToggle() {
  store.dispatch({ type: ON_PARTICIPANT_PANEL_TOGGLE });
}

export function onChangeLayout() {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ON_CHANGE_LAYOUT });
  };
}

export function onSpeakerMuteUnmute() {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ON_SPEAKER_MUTE_UNMUTE });
  };
}

export function onMoreActionClick() {
  store.dispatch({ type: ON_MORE_ACTIONS });
}

export function onMoreActionMobileClick() {
  store.dispatch({ type: ON_MORE_ACTIONS_MOBILE });
}

export function onEndCallButtonClick() {
  store.dispatch({ type: ON_END_CALL });
}

export async function onleaveCall() {
  await proConfService?.leaveCall();
  store.dispatch({ type: CLEAR_INCALL_REDUCER });
  store.dispatch({ type: CLEAR_ROOM_REDUCER });
}

export async function onEndMeeting() {
  await proConfService?.endMeeting();
  store.dispatch({ type: CLEAR_INCALL_REDUCER });
  store.dispatch({ type: CLEAR_ROOM_REDUCER });
}
