import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Components
import Login from "./LoginPage"
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";

//Actions
import { callLoginAPI, callJoinAsGuestnAPI } from "../../Redux/Actions/LoginAction";
import { clearMeetingDataFromStore } from "../../Redux/Actions/StoreAction";

//Constants
import { ROUTES } from "../../Routes/RouteConstants";
import { API_STATUS } from "../../Constants/AppConstants";

//Utility
import { getRoomFromURL } from "../../Utility/Utils";

//Services
import proConfService from "../../Services/ProConfService";

export default function LoginContainer() {

    const navigate = useNavigate();

    //states
    const [isLoading, setIsLoading] = useState(false);
    const [loginApiErrorMsg, setLoginApiErrorMsg] = useState("");
    const [joinApiErrorMsg, setJoinApiErrorMsg] = useState("");

    // Invited participant via link
    const roomnameFromURL = getRoomFromURL();
    console.log('roomnameFromURL: ', roomnameFromURL);


    useEffect(() => {
        clearMeetingDataFromStore(true);
    }, []);

    async function onLoginClick(username: string, password: string) {
        setIsLoading(true)

        let response = await callLoginAPI(username, password)

        if (response?.status === API_STATUS.SUCCESS) {

            navigate(ROUTES.LANDING_PAGE);
            await proConfService.initProConf();

        }
        else {
            setIsLoading(false)
            setLoginApiErrorMsg(response?.response?.data?.error?.message)
        }
    }

    /*
    * Join as guest
    */
    async function onJoinClick(userName: string, roomName: string) {

        setIsLoading(true)
        let response = await callJoinAsGuestnAPI(userName, roomName)

        if (response?.status === API_STATUS.SUCCESS) {
            
            await proConfService.initProConf();
            navigate(ROUTES.CREATE_MEETING);

        }
        else {
            setJoinApiErrorMsg(response?.response?.data?.error?.message)
        }
        setIsLoading(false)
    }

    return (
        <>
            {isLoading && <LoadingSpinner />}
            <Login
                onLoginClick={(username: string, password: string) =>
                    onLoginClick(username, password)}
                loginApiErrorMsg={loginApiErrorMsg}
                setLoginApiErrorMsg={(error: string) => setLoginApiErrorMsg(error)}
                onJoinClick={(userName: string, roomName: string) => onJoinClick(userName, roomName)}
                joinApiErrorMsg={joinApiErrorMsg}
                setJoinApiErrorMsg={(error: string) => setJoinApiErrorMsg(error)}
                roomnameFromURL={roomnameFromURL || ''}
            />
        </>
    );
}