import React, { Suspense } from "react";


//Constants
import { ROUTES } from "./RouteConstants";

//Components
import LandingPageContainer from "../Components/LandingPage/LandingPageContainer";
import CreateMeetingContainer from "../Components/MeetingPreview/MeetingPreviewContainer";
import InCallContainer from "../Components/InCallComponent/InCallContainer";

//Components


const routes = [
    {
        path: ROUTES.LANDING_PAGE,
        exact: true,
        Component: () => (
            < LandingPageContainer />
        ),
    },
    {
        path: ROUTES.CREATE_MEETING,
        exact: true,
        Component: () => (
            < CreateMeetingContainer />
        ),
    },
    {
        path: ROUTES.IN_CALL,
        exact: true,
        Component: () => (
            < InCallContainer />
        ),
    },
]

export default routes;