import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//Components
import CreateMeeting from "./MeetingPreview";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";

//Routes
import { ROUTES } from "../../Routes/RouteConstants";

//Actions
import { logoutAction } from "../../Redux/Actions/LoginAction";
import {
	createMeeting,
	getCallStatus,
	getCatchup,
	joinMeeting,
} from "../../Redux/Actions/MeetingListAction";

//Services
import proConfService from "../../Services/ProConfService";
import MeetingCatchup from "../MeetingCatchup/MeetingCatchup";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";

import "./MeetingPreview.scss";

const CreateMeetingContainer: FunctionComponent = () => {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const [roomName, setRoomName] = useState(
		useSelector((state: any) => state.RoomReducer?.roomName.trim()) || ""
	);
	const [catchUpText, setCatchupText] = useState("");

	const userName = useSelector((state: any) => state.RoomReducer.userName);
	const isCreateMeeting = useSelector(
		(state: any) => state.RoomReducer.isCreatemeeting
	);
	const loggedInUserName = useSelector(
		(state: any) => state.LoginReducer.userName
	);
	const isJoinFlow = userName ?? false;
	const [startClicked, setStartClicked] = useState(true);

	// Catchup
	const [showCatchupUI, setShowCatchupUI] = useState(false);
	const [transcriptStatus, setTranscriptStatus] = useState(false);
	const [isProceedClicked, setProceedClicked] = useState(false);

	useEffect(() => {
		// Need to check this if can be handled in sdk to fix 154
		//(async() => await proConfService.initProConf())();
	}, []);

	/**
	 * @method onBackClick
	 * @description Go back to the previous page either Login Screen or Landing Page
	 * @author Ruchika Phalke <ruchika.phalke@springct.com>
	 */
	const onBackClick = () => {
		proConfService.clearTracks();
		if (isJoinFlow) {
			navigate(ROUTES.ROOT_PATH);
		} else {
			navigate(ROUTES.LANDING_PAGE);
		}
	};

	/**
	 * @method onStartClick
	 * @description To start/join the meeting if user is moderator and join meeting for guest
	 * @author Ruchika Phalke <ruchika.phalke@springct.com>
	 */
	const onStartClick = async (roomName: string) => {
		setIsLoading(true)
		setRoomName(roomName);
		await getCallStatus(roomName)
			.then(async (res: any) => {
				console.log("CallStatus:", res);
				// Check if user can see call catchup before joining existing meeting
				if (res?.transcript_status === true && res?.is_catchup_available) {
					await onViewCatchup();
				} else {
					await normalInCallFlow(roomName);
				}
			})
			.catch(async (err) => {
				await normalInCallFlow(roomName);
				console.log("Error getting call status", err);
			})
			.finally(() => {
				setStartClicked(false);
			});
		setIsLoading(false)
	};

	/**
	 * @method normalInCallFlow
	 * @description Enter meeting based on whether user is moderator or guest
	 */
	const normalInCallFlow = async (room?: string) => {
		const roomname = room || roomName;
		setIsLoading(true);
		try {
			if (isJoinFlow) {
				// Guest user
				await joinMeeting(roomname, userName);
			} else if (!isCreateMeeting) {
				// Join meeting button click for moderator
				await joinMeeting(roomname, loggedInUserName);
			} else {
				// Create meeting button click
				await createMeeting(roomname, loggedInUserName);
			}
			setIsLoading(false);

			navigate(ROUTES.IN_CALL);
		} catch (err) {
			console.error("Error joining meeting!", err);
			if (showCatchupUI) {
				setShowCatchupUI(false);
				window.history.back();
			}
			setIsLoading(false);
		}
	};

	/**
	 * @description Handles the logout functionality by calling the logout action and navigating to the root page.
	 * @method onLogoutClick
	 * @return {Promise<void>} A promise that resolves when the logout action is completed and navigation is successful
	 */
	const onLogoutClick = async () => {
		await logoutAction();
		navigate("/");
	};

	useEffect(() => {
		setTimeout(() => {
			if (isLoading) {
				setIsLoading(false);
			}
		}, 2000);
	}, [isLoading]);

	/**
	 * @method onCloseCatchup
	 * @description Close the catchup UI
	 */
	const onCloseCatchupUI = () => {
		setShowCatchupUI(false);
		setStartClicked(false);
	};

	/**
	 * @method onViewCatchup
	 * @description Call get call catchup API and display the catchup UI
	 */
	const onViewCatchup = async () => {
		setIsLoading(true);

		await getCatchup(roomName) // api call
			.then(async (res: any) => {
				console.log("Catchup data response:", res);
				if (res?.summary?.length > 0) {
					setCatchupText(res?.summary);
					setShowCatchupUI(true);
				} else {
					await normalInCallFlow(roomName);
				}
			})
			.catch(async (err: any) => {
				setIsLoading(false);

				console.error("Error while trying to show catchup: ", err);
				await normalInCallFlow(roomName);
			});
		setIsLoading(false);

	};

	return (
		<>
			{isLoading && <LoadingSpinner />}

			<CreateMeeting
				onBackClick={onBackClick}
				onStartClick={onStartClick}
				onLogoutClick={onLogoutClick}
				isJoinFlow={isJoinFlow}
				isCreateMeeting={isCreateMeeting}
				startClicked={startClicked}
				setStartClicked={setStartClicked}
				roomName={roomName}
				setRoomName={setRoomName}
			/>

			{/* Catchup confirmation popup
      {showCatchupPopup && (
        <PortalPopup placement="Centered">
          <CatchupDialog
            onClose={() => onCloseCatchup()}
            onView={() => onViewCatchup()}
          />
        </PortalPopup>
      )} */}

			{/* UI that displays meeting catchup*/}
			{showCatchupUI && (
				<PortalPopup
					placement="Centered"
					className="join-meeting-popup-container"
				>
					<div className="catchup-popup">
						<MeetingCatchup
							// onClose={() => onCloseCatchupUI()}
							onJoin={() => {
								setProceedClicked(true);
								normalInCallFlow(roomName);
							}}
							text={catchUpText}
							isProceedClicked={isProceedClicked}
						/>
					</div>
				</PortalPopup>
			)}
		</>
	);
};

export default CreateMeetingContainer;
