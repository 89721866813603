import { FunctionComponent, useState } from "react";
import { getTranslation } from "../../Resources/Localization/i18n";
import { inviteParticipant } from "../../Redux/Actions/InCallAction";
import ChipInvite from "../Chip/Chip";
import { Input } from "antd";

// Assets
import CrossIcon from "../../Resources/Images/icadd21@2x.png";
import errorImg from "../../Resources/Images/icerror.svg";

// Styles
import "./InviteViaEmailPopup.scss";
import { validateEmail } from "../../Utility/Utils";
import {
  setErrorNotification,
  setSuccessNotification,
} from "../../Redux/Actions/NotificationAction";
import { CLEAR_NOTIFICATIONS } from "../../Redux/ReduxConstants";
import { store } from "../../Redux/store";

export type InviteViaEmailPopUpType = {
  className?: string;
  setInvitePopup: (value: boolean) => void;
};

const InviteViaEmailPopUp: FunctionComponent<InviteViaEmailPopUpType> = ({
  className = "",
  setInvitePopup,
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);

  /**
   * Sends an invitation to the participants via email.
   *
   * @return {Promise<void>} A promise that resolves when the invitation is sent successfully.
   */
  const sendInvite = async () => {
    setDisabled(true);
    let emailsArray = emails;
    // Check for emails separated by semicolons
    if (email.includes(";")) {
      emailsArray = [...emailsArray, ...splitEmails(email)];
    }
    // Take email from the textbox if any and push in array
    else if (email && validateEmail(email) && email.trim().length > 0) {
      emailsArray = [...emails, email];
      setEmails([...emails, email]);
    }
    setEmail("");
    await inviteParticipant(emailsArray)
      .then((res: any) => {
        if (res) {
          console.log("Invite response:", res);
          setEmails([]);
          setInvitePopup(false);
          if (res?.code === 200) {
            setSuccessNotification(getTranslation("inviteSuccess"));
          } else if (res.error && res.error?.message) {
            setErrorNotification(getTranslation("inviteError"));
            console.error("Invite error: ", res.error?.message);
          }
        }
      })
      .catch((error: any) => {
        setInvitePopup(false);
        setErrorNotification(getTranslation("inviteError"));
      });
    setError("");
    setTimeout(() => {
      store.dispatch({ type: CLEAR_NOTIFICATIONS });
    }, 3000);
  };

  /**
   * Handles changes to the input field, updating the email state accordingly.
   *
   * @param {any} event - The event object triggered by the input change.
   * @return {void} No return value.
   */
  const handleInputChange = (event: any) => {
    setError("");
    let value = event.target.value;
    setEmail(value);
    if (value.includes(";")) {
      setEmails([...emails, ...splitEmails(value)]);
      setEmail("");
      setError("");
    }
  };

  /**
   * Handles the key down event, specifically the "Enter" key press.
   *
   * @param {any} event - The event object triggered by the key press.
   * @return {void} No return value.
   */
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" || event.code === "Space") {
      // Check for emails separated by semicolons
      if (email.includes(";")) {
        setEmails([...emails, ...splitEmails(email)]);
        setEmail("");
      } else if (validateEmail(email) && email.trim().length > 0) {
        setEmails([...emails, email]);
        setEmail("");
      } else {
        setError("Invalid email");
      }
    }
  };

  /**
   * Split email ids based on a semicolon
   * @param {string} email
   * @returns {Array}
   */
  const splitEmails = (email: string) => {
    let semiColonArray = email.split(/\s*;\s*/);
    let emailsArray: string[] = [];
    semiColonArray?.forEach((mail: string) => {
      if (validateEmail(mail) && mail.trim().length > 0) {
        emailsArray = [...emailsArray, mail];
      } else {
        setError("Invalid email");
      }
    });
    return emailsArray;
  };

  /**
   * Removes an email from the emails array at the specified index.
   *
   * @param {number} index - The index of the email to be removed.
   * @return {void} This function does not return anything.
   */
  const removeEmail = (index: number) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  return (
    <div className={`invite-via-email-pop-up ${className}`}>
      <div className="tex-box3">
        <div className="create-an-account-parent">
          <b className="create-an-account">
            {getTranslation("emailMembersInvite")}
          </b>
          <button
            className="buttonsignin24"
            onClick={() => {
              setInvitePopup(false);
            }}
          >
            <img className="icadd2-icon15" alt="" src={CrossIcon} />
          </button>
        </div>
        {/* <div className="create-an-account1">{getTranslation("enterDetails")}</div> */}
        <div className="popupcontent">
          <div className="inputfield21">
            <div className="emaillabel">{getTranslation("emailAddr")}</div>
            <Input
              className="inputfield15"
              size="large"
              type="email"
              bordered={true}
              placeholder={getTranslation("emailPlaceholder")}
              required
              maxLength={50}
              name="email"
              value={email || ""}
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
            />

            {/**Error message */}
            {error && (
              <div className="error2">
                <img className="icerror-icon" alt="" src={errorImg} />
                <div className="errormessage">{error}</div>
              </div>
            )}

            {/**Selected emails */}
            {emails &&
              emails?.map((email, index) => (
                <ChipInvite
                  emailtext={email}
                  index={index}
                  removeEmail={() => removeEmail(index)}
                />
              ))}
          </div>
          <button
            className={
              (!email && emails.length === 0) || disabled
                ? "disabled-button buttonprimary12"
                : "buttonprimary12"
            }
            onClick={() => sendInvite()}
            disabled={(!email && !emails) || disabled}
          >
            <b className="buttonlabel16">{getTranslation("sendInvite")}</b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteViaEmailPopUp;
