import { AnyAction, combineReducers } from 'redux';


//Reducers
import ProConfReducer from './Reducers/ProConfReducer';
import LoginReducer from './Reducers/LoginReducer';
import RoomReducer from './Reducers/RoomReducer';
import ParticipantReducer from './Reducers/ParticipantReducer';
import ToolbarReducer from './Reducers/ToolbarReducer';
import InCallReducer from './Reducers/InCallReducer';
import NotificationReducer from './Reducers/NotificationReducer';

//Constant
import { LOGOUT } from './ReduxConstants';


// Combine all reducers into a single root reducer
const appReducer = combineReducers({
	ProConfReducer,
	LoginReducer,
	RoomReducer,
	ParticipantReducer,
	ToolbarReducer,
	InCallReducer,
	NotificationReducer
});

const rootReducer = (state: any, action: any) => {
	if (action.type === LOGOUT) {
		state = undefined;
		return state;
	}
	return appReducer(state, action);
};

export default rootReducer;