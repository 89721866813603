import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { LocalTrack } from "proconf-web-sdk";

//Component
import InCall from "./InCallComponent";

//store
import { store } from "../../Redux/store";
import { useSelector } from "react-redux";
import proConfService from "../../Services/ProConfService";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/RouteConstants";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";
import InviteViaEmailPopUp from "../InviteParticipant/InviteViaEmailPopup";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";

const InCallContainer: FunctionComponent = () => {
  const room = store.getState().ProConfReducer.room;
  const localParticipant =
    store.getState().ParticipantReducer?.localParticipant;
  let localVideoTracks = null;
  let localAudioTracks = null;
  const [openInvitePopup, setInvitePopup] = useState(false);

  if (
    localParticipant &&
    typeof localParticipant?.videoTracks?.values === "function"
  ) {
    // Call the function here
    localVideoTracks = localParticipant?.videoTracks?.values();
  } else {
    localVideoTracks = null;
    console.log(
      "_localParticipant$vid.values is not a function or _localParticipant$vid is not valid."
    );
  }
  //const localVideoTracks = localParticipant ? localParticipant?.videoTracks?.values() : null;
  const firstVideoTrack = localVideoTracks
    ? Array.from(localVideoTracks)[0]
    : null;
  const videoTrack = firstVideoTrack ? (firstVideoTrack as LocalTrack) : null;

  if (
    localParticipant &&
    typeof localParticipant?.audioTracks?.values === "function"
  ) {
    // Call the function here
    localAudioTracks = localParticipant?.audioTracks?.values();
  } else {
    localAudioTracks = null;
    console.log(
      "_localParticipant$vid.values is not a function or _localParticipant$vid is not valid."
    );
  }

  const firstAudioTrack = localAudioTracks
    ? Array.from(localAudioTracks)[0]
    : null;
  const audioTrack = firstAudioTrack ? (firstAudioTrack as LocalTrack) : null;

  const isVideoOn = useSelector(
    (state: any) => state?.ToolbarReducer?.videoMute
  );
  const meetingName = useSelector((state: any) => state?.RoomReducer?.roomName);
  const isGuest = store.getState()?.RoomReducer.userName ?? false;
  const isCallInProgress = useSelector(
    (state: any) => state?.RoomReducer?.callInProgress
  );
  const isCreateMeeting = useSelector(
    (state: any) => state.RoomReducer?.isCreatemeeting
  );
  const username = isGuest
    ? store.getState()?.RoomReducer.userName
    : store.getState()?.LoginReducer?.userName;
  const roomname = store.getState()?.RoomReducer?.roomName;
  const isRoomEnded = useSelector((state: any) => state.InCallReducer?.roomEnded);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Device orientation change
    // window.addEventListener("orientationchange", () => adjustDivSize());
    // Also handle window resize events
    window.addEventListener("resize", () => adjustDivSize());

    if (isCallInProgress === false) {
      store.dispatch({
        type: "CALL_IN_PROGRESS",
        payload: true,
      });
    } else {
      handlePageReload({});
    }
    return () => {
      // unmount
      console.log("InCallContainer:::unmount");
      proConfService.clearTracks();
      store.dispatch({ type: "CLEAR_PARTICIPANTS" });
      store.dispatch({ type: "CLEAR_ROOM" });
      store.dispatch({ type: "RESET_TOOLBAR_STATE" });
      store.dispatch({ type: "SET_REMOTE_SS", payload: false });
      window.removeEventListener("resize", () => adjustDivSize());
    };
  }, []);

  useMemo(() => {
    if (isRoomEnded === true) {
      if (isGuest) {
        navigate(ROUTES.ROOT_PATH)
      } else {
        navigate(ROUTES.LANDING_PAGE);
      }
    }
  }, [isGuest, isRoomEnded, navigate]);


  // Rejoin user
  const rejoinParticipant = async () => {
    if (isCreateMeeting === false && roomname) {
      await proConfService.joinMeeting(roomname, username).then((room) => {
        console.info("Restart meeting: ", room, username);
        setLoading(false);
        navigate(ROUTES.IN_CALL);
      });
    }
    if (isCreateMeeting === true && roomname) {
      await proConfService.startMeeting(roomname, username).then((room) => {
        console.info("Rejoin meeting: ", room, username);
        setLoading(false);
        navigate(ROUTES.IN_CALL);
      });
    }
  };

  /*
   * Handle page reload when call is in progress
   *(wait for 5 seconds until init is successful)
   */
  const handlePageReload = async (event: any) => {
    try {
      store.dispatch({ type: "CLEAR_PARTICIPANTS" });
      if (store.getState()?.ToolbarReducer.screenShareStarted) {
        store.dispatch({ type: "ON_SCREEN_SHARE_TOGGLE" });
      }
      setLoading(true);
      setTimeout(async () => {
        if (!store.getState()?.ProConfReducer.isInitDone) {
          proConfService.initProConf();
          rejoinParticipant();
        } else {
          rejoinParticipant();
        }
      }, 5000);
    } catch (error) {
      console.log("Error while rejoining:", error);
      setLoading(false);
      window.history.back();
    }
  };

  // Code to handle orientation changes for shared screen UI
  function adjustDivSize() {
    const mydiv = document.getElementById("screen-div");
    if (mydiv) {
      if (window.innerHeight > window.innerWidth) {
        // Portrait mode
        mydiv.classList.remove("landscape-screen");
        mydiv.classList.add("portrait-screen");
      } else {
        // Landscape mode
        mydiv.classList.remove("portrait-screen");
        mydiv.classList.add("landscape-screen");
      }
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <InCall meetingName={meetingName} setInvitePopup={setInvitePopup} />
      {openInvitePopup && (
        <PortalPopup placement="Centered">
          <InviteViaEmailPopUp setInvitePopup={setInvitePopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default InCallContainer;
